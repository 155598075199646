.App {
  text-align: center;
  /* background-color: #282c34; */
  min-height: 100vh;
}

.Spinning-headshot {
  height: 25vmin;
  cursor: pointer;
}

.Still-headshot {
  height: 75vmin;
  filter: saturate(400%);
  border-radius: 40%;
}

.home-image {
  height: 75vmin;
  transform: rotate(-5deg);
}

.metadata-menu {
  vertical-align: top;
}

.body-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 40vmin;
  margin: 1rem 0;
}

.feature-image img {
  max-height: 12vmin;
}

.feature-content {
  text-align: center;
}

.feature-list {
  list-style-position: inside;
  padding: 0;
  text-align: left;
}

.small-text {
  font-size: 10px;
}

@media (min-width: 768px) {
  .body-home {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }

  .feature {
    margin: 1rem;
  }
}

.transition-fade {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.body-home {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-text {
  color: rgb(0, 0, 0);
  padding-left: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Under-construction {
  height: 75vmin;
  border-radius: 10%;
  padding-top: 10px;
}

.Glowing-eyes-base {
  width: 5vmin;
  height: 3.5vmin;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 5vmin 2.5vmin #fff, /* inner white */ 0 0 8.333vmin 5vmin #f0f,
    /* middle magenta */ 0 0 11.667vmin 7.5vmin #0ff; /* outer cyan */
  position: relative;
  opacity: 85%;
  cursor: pointer;
}
.Glowing-eyes-left {
  top: -45vmin;
  left: 27vmin;
}
.Glowing-eyes-right {
  top: -47.5vmin;
  left: 43vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .Spinning-headshot {
    animation: Headshot-spin infinite 4s linear;
  }
}

.Headshot-container {
  position: relative; /* Set the position of the container to relative */
  display: inline-block; /* This ensures that the container takes the size of the image */
}

.linkedin {
  height: 20px;
  padding-left: 5px;
  cursor: pointer;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 5vh; */
  display: flex;
  /* flex-direction: column; */
  align-items: top;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.header-bar {
  /* background-color: #282c34; */
  /* min-height: 5vh; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes Headshot-spin {
  0% {
    transform: rotate(0deg) scale(1) translate(0px, 0px);
    filter: saturate(100%);
  }
  25% {
    transform: rotate(180deg) scale(3) translate(250px, 50px);
    filter: saturate(50%);
  }
  50% {
    transform: rotate(360deg) scale(2) translate(0px, 0px);
    filter: saturate(0%);
  }
  75% {
    transform: rotate(540deg) scale(3) translate(-250px, 50px);
    filter: saturate(50%);
  }
  100% {
    transform: rotate(720deg) scale(1) translate(0px, 0px);
    filter: saturate(100%);
  }
}

.Tic-Tac-Toe-Box {
  width: 25vmin;
  height: 25vmin;
  border: solid #5b6dcd 3px;
  padding: 5px;
}

.Tic-Tac-Toe-Grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  cursor: pointer;
}

.Cell-Value {
  font-family: "Trebuchet MS", sans-serif;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 25vmin;
  line-height: 25vmin;
}

.X-Color {
  color: #0077ff;
  cursor: default;
}

.O-Color {
  color: #ff0000;
  cursor: default;
}

.Nim-Board {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Game-Container {
  height: 50vmin;
  width: 50vmin;
  align-items: center;
  position: relative;
  margin: 15px;
}

.Nim-Dot {
  position: absolute;
  width: 5vmin;
  height: 5vmin;
  border-radius: 50%;
  background: red;
  cursor: pointer;
  margin: 5px;
}

.Nim-Dot-Off {
  background: transparent;
  cursor: default;
}

.End-Turn-Button {
  width: 10vmin;
  height: 5vmin;
  align-items: center;
  position: relative;
  margin: 15px;
}
